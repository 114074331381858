export enum IconNames {
  warning = 'warning',
  user2 = 'user2',
  user = 'user',
  task = 'task',
  tag = 'tag',
  success = 'success',
  new = 'new',
  menu = 'menu',
  info = 'info',
  heart = 'heart',
  expand = 'expand',
  error = 'error',
  danger = 'danger',
  'credit-card' = 'credit-card',
  close = 'close',
  'chevron-right' = 'chevron-right',
  'chevron-left' = 'chevron-left',
  'chevron-down' = 'chevron-down',
  'calendar' = 'calendar',
}
